import { PDFDocument } from "pdf-lib"
import extraPagePath from "./pdfForms/stand_page_no_values.pdf"

// ** Custom
import { getFormattedDate } from "./utils"

const determinePageCount = (itemCount, withBasePage) => {
  let quotient = itemCount / 9 < 1 ? 0 : Math.floor(itemCount / 9)
  let remainder = itemCount % 9 > 0 ? 1 : 0 // NOTE: There are nine rows on the full pages.
  let total = quotient + remainder

  if (withBasePage)
    return total + 1 // Need to account for the first page (+1).
  else return total
}

const createPageAndRenameFields = async (pageNumber) => {
  const existingPdfBytes = await fetch(extraPagePath).then((res) => res.arrayBuffer())
  const extraPage = await PDFDocument.load(existingPdfBytes)
  let extraForm = extraPage.getForm()
  let extraFormFields = extraForm.getFields()

  extraFormFields.forEach((field) => {
    let newName = field.getName() + "_p" + pageNumber
    field.acroField.setPartialName(newName)
  })

  extraPage.save()
  // NOTE: Testing to view form fields.
  // extraFormFields.forEach((field) => {
  //   const name = field.getName()
  //   if (!name.includes("Reset")) {
  //     const textField = extraForm.getTextField(name)
  //     const widgets = textField.acroField.getWidgets()
  //     widgets.forEach((w) => {
  //       let page = extraPage.getPages().find((p) => p.ref === w.P())
  //       let pageIndex = extraPage.getPages().findIndex((p) => p.ref === w.P())
  //       console.log(`${pageIndex}: ${name}`)
  //     })
  //   }
  // })

  return extraPage
}

// Populate data for ONLY the base page (first page).
const fillBasePage = (baseForm, totalPages, warehouseRequest, formattedRequestItems, monetaryFormatter) => {
  // Get fields in top section:
  let fromAddress = baseForm.getTextField("1_FROM_Include_ZIP_Code")
  let toAddress = baseForm.getTextField("2_TO_Include_ZIP_Code")
  let shipToMarkFor = baseForm.getTextField("3_SHIP_TO__MARK_FOR")
  let appropriationsData = baseForm.getTextField("4_APPROPRIATIONS_DATA")
  let requisitionNumber = baseForm.getTextField("6_REQUISITION_NUMBER")
  let dateMaterialRequired = baseForm.getTextField("7_DATE_MATERIAL_REQUIRED")
  let priority = baseForm.getTextField("8_PRIORITY")
  let authorityOrPurpose = baseForm.getTextField("9_AUTHORITY_OR_PURPOSE")
  let dateShipped = baseForm.getTextField("12_DATE_SHIPPED_YYYYMMDD")
  let carrier = baseForm.getTextField("13_MODE_OF_SHIPMENT")
  let trackingNumber = baseForm.getTextField("14_BILL_OF_LADING_NUMBER")
  let shippingCost = baseForm.getTextField("AMOUNT")
  let requisitionDate = baseForm.getTextField("reqdate")
  let sheetTotal = baseForm.getTextField("SHEET_TOTAL")
  let grandTotal = baseForm.getTextField("GRAND_TOTAL")
  let sheetNumber = baseForm.getTextField("sheet")
  let totalSheets = baseForm.getTextField("sheets")

  let fromAddressText = `RMLA DISTRIBUTION CENTER\n`
  fromAddressText += `DODAAC: ${warehouseRequest.dodAac_Uic}\n`

  // Configures the address based on whether the WRT is CONUS or OCONUS.
  let toAddressText = warehouseRequest.isConus
    ? `${warehouseRequest.city}, ${warehouseRequest.state} ${warehouseRequest.zipCode}`
    : warehouseRequest.isConus == null
      ? ""
      : `${warehouseRequest.city}, ${warehouseRequest.country}`

  let carrierText
  if (warehouseRequest.requestType.includes("Movement")) carrierText = "LOCAL DELIVERY"
  else if (warehouseRequest.requestType.includes("Pull"))
    carrierText = !warehouseRequest.carrierInformation ? "" : warehouseRequest.carrierInformation
  else if (warehouseRequest.requestType.includes("Pickup")) carrierText = "LOCAL PICKUP"
  else carrierText = ""

  // Show TAC/SLIN information if it's a pull request; for local pick-ups show any notes.
  let appropriationsDataText = warehouseRequest.requestType.includes("Pull")
    ? `TAC: ${!warehouseRequest.transportationAccountCode ? "N/A" : warehouseRequest.transportationAccountCode}\nSLIN: ${!warehouseRequest.sublineItemNumber ? "N/A" : warehouseRequest.sublineItemNumber}\n`
    : `Notes: ${!warehouseRequest.notes ? "" : warehouseRequest.notes}`

  // Populate fields in top section:
  fromAddress.setText(fromAddressText)
  toAddress.setText(`${warehouseRequest.address}\n${toAddressText}`)
  shipToMarkFor.setText(`ATTN: ${warehouseRequest.pocName}\nPHONE: ${warehouseRequest.pocPhone}`)
  appropriationsData.setText(appropriationsDataText)
  requisitionNumber.setText(warehouseRequest.documentNumber)
  dateMaterialRequired.setText(getFormattedDate(warehouseRequest.requiredDeliveryDate).replace(/-/g, ""))
  priority.setText(warehouseRequest.priority.includes("High") ? "1" : warehouseRequest.priority.includes("Medium") ? "2" : "3")
  authorityOrPurpose.setText(warehouseRequest.programName)
  dateShipped.setText(!warehouseRequest.finalDepartureDate ? "" : getFormattedDate(warehouseRequest.finalDepartureDate).replace(/-/g, ""))
  carrier.setText(carrierText)
  trackingNumber.setText(!warehouseRequest.trackingNumber ? "" : warehouseRequest.trackingNumber)
  shippingCost.setText(!warehouseRequest.shippingcost ? "N/A" : monetaryFormatter.format(warehouseRequest.shippingCost))
  requisitionDate.setText(getFormattedDate(warehouseRequest.requestDate).replace(/-/g, ""))
  grandTotal.setText(monetaryFormatter.format(warehouseRequest.estimatedTotalPrice))
  sheetNumber.setText("1")

  let nextIndex
  let basePageTotal = 0
  // Populate the request items on the base page.
  formattedRequestItems.forEach((item, index) => {
    let itemIndex
    let description
    let unitOfIssue
    let quantity
    let unitPrice
    let totalCost

    let serialNumbers
    if (item.length > 1) serialNumbers = item.map((f) => f.serialNumber)

    // Fill in the data as long as the last line on the base page hasn't been filled out.
    if (index < 4 || !baseForm.getTextField("item5").getText()) {
      itemIndex = baseForm.getTextField(`item${index + 1}`)
      description = baseForm.getTextField(`desc${index + 1}`)
      unitOfIssue = baseForm.getTextField(`unit${index + 1}`)
      quantity = baseForm.getTextField(`qty${index + 1}`)
      unitPrice = baseForm.getTextField(`unitpr${index + 1}`)
      totalCost = baseForm.getTextField(`cost${index + 1}`)

      let itemDescriptionFirstPart = `NSN: ${item[0].nationalStockNumber} P/N: ${item[0].partNumber} ${item[0].nomenclature}`

      // If item has multiple serials, put in message to reference the serials on a reference page.
      let itemDescription = `${itemDescriptionFirstPart.substring(0, 61)}\nS/N: ${
        serialNumbers ? "PLEASE SEE REF. PAGE(S) FOR SERIAL NUMBERS." : item[0].serialNumber
      }`

      itemIndex.setText(`${index + 1}`)
      description.setText(`${itemDescription}`)
      unitOfIssue.setText(`${!item[0].unitOfIssue ? "" : item[0].unitOfIssue}`)
      quantity.setText(`${serialNumbers ? serialNumbers.length : item[0].quantity}`)
      unitPrice.setText(`${monetaryFormatter.format(item[0].unitPrice)}`)
      totalCost.setText(
        `${serialNumbers ? monetaryFormatter.format(item[0].unitPrice * serialNumbers.length) : monetaryFormatter.format(item[0].totalCost)}`
      )

      // Calculate the base sheet total.
      basePageTotal += serialNumbers ? item[0].unitPrice * serialNumbers.length : item[0].totalCost

      // Capture the next index to start the second page (as needed).
      nextIndex = index + 1
    }
  })

  sheetTotal.setText(monetaryFormatter.format(basePageTotal))
  totalSheets.setText(totalPages.toString())
  return nextIndex
}

// Populate data for any page(s) following the base page.
const fillExtraPage = async (form, pageNumber, totalPages, requestItems, reqNumber, monetaryFormatter) => {
  let requisitionNumber = form.getTextField(`6_REQUISITION_NUMBER_p${pageNumber}`)
  let sheetNumber = form.getTextField(`SHEET_NO_p${pageNumber}`)
  let totalSheets = form.getTextField(`sheets_p${pageNumber}`)
  let sheetTotal = form.getTextField(`sht_total_p${pageNumber}`)

  let nextIndex
  let pageTotal = 0
  let lineNumber = pageNumber === 1 ? 6 : (pageNumber - 1) * 9 + 6

  requestItems.forEach((item, index) => {
    let itemIndex
    let description
    let unitOfIssue
    let quantity
    let unitPrice
    let totalCost

    let serialNumbers
    if (item.length > 1) serialNumbers = item.map((f) => f.serialNumber)

    // Fill in the data as long as the last line on the page hasn't been filled out.
    if (index < 9 || !form.getTextField(`item_9_p${pageNumber}`).getText()) {
      itemIndex = form.getTextField(`item_${index + 1}_p${pageNumber}`)
      description = form.getTextField(`descr_${index + 1}_p${pageNumber}`)
      unitOfIssue = form.getTextField(`unit_${index + 1}_p${pageNumber}`)
      quantity = form.getTextField(`qty_${index + 1}_p${pageNumber}`)
      unitPrice = form.getTextField(`unit_pr_${index + 1}_p${pageNumber}`)
      totalCost = form.getTextField(`totalcost_${index + 1}_p${pageNumber}`)

      let itemDescriptionFirstPart = `NSN: ${item[0].nationalStockNumber} P/N: ${item[0].partNumber} ${item[0].nomenclature}`

      let itemDescription = `${itemDescriptionFirstPart.substring(0, 122)}\nS/N: ${
        serialNumbers ? "PLEASE SEE REF. PAGE(S) FOR SERIAL NUMBERS." : item[0].serialNumber
      }`

      itemIndex.setText(`${lineNumber}`)
      description.setText(`${itemDescription}`)
      unitOfIssue.setText(`${!item[0].unitOfIssue ? "" : item[0].unitOfIssue}`)
      quantity.setText(`${serialNumbers ? serialNumbers.length : item[0].quantity}`)
      unitPrice.setText(`${monetaryFormatter.format(item[0].unitPrice)}`)
      totalCost.setText(
        `${serialNumbers ? monetaryFormatter.format(item[0].unitPrice * serialNumbers.length) : monetaryFormatter.format(item[0].totalCost)}`
      )

      lineNumber += 1

      // Calculate the base sheet total.
      pageTotal += serialNumbers ? item[0].unitPrice * serialNumbers.length : item[0].totalCost

      // Capture the next index to start the next page (as needed).
      nextIndex = index + 1
    }
  })

  requisitionNumber.setText(reqNumber)
  sheetNumber.setText((pageNumber + 1).toString())
  totalSheets.setText(totalPages.toString())
  sheetTotal.setText(monetaryFormatter.format(pageTotal))
  return nextIndex
}

const copyAndMergePage = async (finalPdf, pageToCopy) => {
  const copy = await finalPdf.copyPages(pageToCopy, [0])
  finalPdf.addPage(copy[0])
  await finalPdf.save()
}

// Sort and format grouped serial numbers for easier listing.
const sortSerialNumberItems = (serialNumberItems) => {
  /*
   * NOTE: In the cases where the serialNumberItems list is empty, a reference page is still added
   * when it shouldn't be added. And in the event the reference page IS needed, it isn't added to the
   * generated document. The below fix should handle this.
   */
  let rows = serialNumberItems.length === 0 ? 0 : 1
  let finalList = []

  serialNumberItems.map((item) => {
    let serialNumbersFinalRows = []
    let serialNumberLine = []

    let serialNumbers = item.map((f) => f.serialNumber)
    let itemDescription = `NSN: ${item[0].nationalStockNumber} P/N: ${item[0].partNumber} ${item[0].nomenclature}`

    serialNumbers.forEach((number) => {
      // Line can hold 60 characters (well), but want to account for adding a comma at the end.
      if (serialNumberLine.join(", ").length + number.length < 60) serialNumberLine.push(number)
      else {
        serialNumbersFinalRows.push(serialNumberLine.join(", "))
        rows = rows + 1
        serialNumberLine = [] // Set array to empty to start a new "line".
        serialNumberLine.push(number)
      }
    })

    // Push any remaining serial numbers to the final array.
    serialNumbersFinalRows.push(serialNumberLine.join(", "))

    let body = {
      itemDescription: itemDescription.substring(0, 61),
      serialNumbers: serialNumbersFinalRows,
    }

    finalList.push(body)
  })

  // Determine how many pages will be needed for the serial number lines.
  let quotient = rows / 2 < 1 ? 0 : Math.floor(rows / 2)
  let remainder = rows % 2 > 0 ? 1 : 0
  let total = quotient + remainder

  return { finalSortedSerialList: finalList, totalLines: total }
}

// Populate the serial numbers for the reference page(s).
const fillExtraSerialNumbers = async (form, pageNumber, serialNumberItems) => {
  let currIndex = 1
  let requisitionNumber = form.getTextField(`6_REQUISITION_NUMBER_p${pageNumber}`)
  requisitionNumber.setText("SERIAL NUMBERS REFERENCE PAGE")
  let description

  serialNumberItems.forEach((item) => {
    let finishedListing = false

    while (!finishedListing) {
      let row
      let sliceAmount
      if (item.serialNumbers[1]) {
        row = item.serialNumbers[0].concat(", ", item.serialNumbers[1])
        sliceAmount = 2
      } else {
        row = item.serialNumbers[0]
        sliceAmount = 1
      }

      description = form.getTextField(`descr_${currIndex}_p${pageNumber}`)

      if (currIndex < 9 || !form.getTextField(`item_9_p${pageNumber}`).getText()) {
        let itemDescription = `${item.itemDescription}\n${row}`
        description.setText(`${itemDescription}`)
      }

      // Remove indice(s) from startArray and increase row index.
      item.serialNumbers = item.serialNumbers.slice(sliceAmount)
      currIndex = currIndex + 1

      // Move to the next item once the current item's serial number list is empty.
      if (item.serialNumbers.length === 0) finishedListing = true
    }
  })
}

export default async function fillPdfForm(warehouseRequest, formattedRequestItems) {
  // Splits the items with more than one serial numbers to be listed later.
  const separateSerializedItems = (requestItems) => {
    let serialNumbers = []
    requestItems.forEach((item) => {
      if (item.length > 1) serialNumbers.push(item)
    })

    return serialNumbers
  }

  // For formatting monetary values.
  const USDollar = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })

  // Load the PDf from the URL (for now).
  const formUrl = "https://www.dcma.mil/Portals/31/Documents/NPP/Forms/dd1149.pdf"
  const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer())
  const basePage = await PDFDocument.load(formPdfBytes, { ignoreEncryption: true })

  // Grab those items that have multiple serial numbers.
  let serialNumberItems = separateSerializedItems(formattedRequestItems)

  // Remove second page and fill out first page.
  basePage.removePage(1)
  basePage.save()

  let baseForm = basePage.getForm()
  // NOTE: Testing to view form fields.
  // let baseFormFields = baseForm.getFields()
  // baseFormFields.forEach((field) => {
  //   const name = field.getName()
  //   if (name !== "Reset") {
  //     const textField = baseForm.getTextField(name)
  //     const widgets = textField.acroField.getWidgets()
  //     widgets.forEach((w) => {
  //       let page = basePage.getPages().find((p) => p.ref === w.P())
  //       let pageIndex = basePage.getPages().findIndex((p) => p.ref === w.P())
  //       console.log(`${pageIndex}: ${name}`)
  //     })
  //   }
  // })

  let totalPages = determinePageCount(formattedRequestItems.length - 5, true)
  let nextIndex = fillBasePage(baseForm, totalPages, warehouseRequest, formattedRequestItems, USDollar)
  basePage.save()

  const finalPdf = await PDFDocument.create()
  await copyAndMergePage(finalPdf, basePage)

  // Check if extra pages are necessary:
  let cutFormattedRequestItems = formattedRequestItems.slice(nextIndex)
  if (cutFormattedRequestItems.length > 0) {
    let pageCount = determinePageCount(cutFormattedRequestItems.length, false)
    for (let x = 1; x <= pageCount; x++) {
      const newPage = await createPageAndRenameFields(x)
      const newIndex = await fillExtraPage(newPage.getForm(), x, pageCount + 1, cutFormattedRequestItems, warehouseRequest.documentNumber, USDollar)
      newPage.save()

      // Slice off the first {newIndex} values to get the new array of values to add to the next page.
      cutFormattedRequestItems = cutFormattedRequestItems.slice(newIndex)

      // Merge page into the final PDF.
      await copyAndMergePage(finalPdf, newPage)
    }
  }

  // Grab the items with multiple serial numbers and list out on separate page(s).
  const returnedObj = sortSerialNumberItems(serialNumberItems)
  const { finalSortedSerialList, totalLines } = returnedObj
  const serialPagesCount = determinePageCount(returnedObj.totalLines)
  let trackRemainingSerials = returnedObj.finalSortedSerialList

  for (let x = 1; x <= serialPagesCount; x++) {
    const newPage = await createPageAndRenameFields(totalPages + x)
    const remainingSerials = await fillExtraSerialNumbers(newPage.getForm(), totalPages + x, trackRemainingSerials)
    newPage.save()

    // Slice off the first {newIndex} values to get the new array of values to add to the next page.
    trackRemainingSerials = remainingSerials

    // Merge page into the final PDF.
    await copyAndMergePage(finalPdf, newPage)
  }

  return finalPdf.saveAsBase64({ dataUri: true })
}
