import { useContext, useEffect, useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

// ** Toastify
import { ToastContainer } from "react-toastify"

// ** MUI
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  IconButton,
} from "@mui/material"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Custom
import { numberInUOM } from "../context/variables"
import LoadingBackdrop, { formatEnum, getBase64, getFormattedDate, getProgramName, notify, SetTimeAndOffset } from "../utils"
import { FedLogSelectModal } from "./FedlogSelectModal"
import { InventoryChangeReasonModal } from "./InventoryChangeReasonModal"
import { DuplicatesFoundModal } from "./DuplicatesFoundModal"
import SerialNumbersInputForm from "./SerialNumbersInputForm"
import DocumentsInputForm from "./DocumentsInputForm"

// ** Styles
import "./styles/CustomFormStyles.css"
import "react-toastify/dist/ReactToastify.css"

const CustomForm = ({ leftMenuDrawerOpen, selectedGlobalProgramId, handlePendingChangesCount, handleUpdateUserPrograms }) => {
  let context = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const { entity, operation, id } = params
  const [loaded, setLoaded] = useState(false)
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const [form, setForm] = useState({})
  const [initialForm, setInitialForm] = useState({})
  const [formErrors, setFormErrors] = useState([])
  const [isModalOpen, toggleModal] = useState(false)
  const [fedlogItems, setFedlogItems] = useState({})
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [isReasonsModalOpen, setIsReasonsModalOpen] = useState(false)
  const [isDuplicatesFoundModalOpen, setIsDuplicatesFoundModalOpen] = useState(false)
  const [pendingProperties, setPendingProperties] = useState([])
  const [pendingCount, setPendingCount] = useState(0)
  const [intersectingSerials, setIntersectingSerials] = useState([])

  // Used to track if NSN, NIIN, or P/N have a new value, and if so, to pull FEDLOG data.
  const [fedLogDataPulled, setFedLogDataPulled] = useState(false)
  const [trackFields, setTrackFields] = useState({})

  // For distinguishing b/w pending and non-pending properties (fields differ depending on if user is an Inventory Manager).
  const pattern = context.isInventoryManager
    ? new RegExp(/^(?!.*(assetIdentification|armyEnterpriseSystemIntegrationProgramSerializationIndicator)).*$/)
    : new RegExp(/^(?!.*(cosisDueDate|cosisCompleteDate|conditionCode|packagingDeficiency)).*$/)

  // For serial number entry:
  const [radioValue, setRadioValue] = useState("")
  const [finalSerialNumberRows, setFinalSerialNumberRows] = useState([])

  // For document entry:
  const [finalDocumentRows, setFinalDocumentRows] = useState([])

  // User feedback messages:
  let successMessageToDisplay = `${
    operation === "new" ? `New ${location.state.title} item added` : `${location.state.title} item updated`
  } successfully.`
  let errorMessageToDisplay = `There was a problem ${operation === "new" ? "adding" : "updating"} your ${location.state.title} item.`

  useEffect(() => {
    if (location.pathname.includes("new")) {
      setForm(context[`blank${applyInitCaps(entity)}Template`])
    }
    setFormBasedOnType()
  }, [operation])

  useEffect(() => {
    checkForChanges()
  }, [form])

  // Checks whether any changes are made to the form.
  const checkForChanges = () => {
    if (operation === "edit") {
      if (form === initialForm) {
        setIsButtonDisabled(true)
      }

      let changesMade = 0
      let falseChanges = 0

      for (let key of Object.keys(form)) {
        if (form[key] !== initialForm[key]) {
          changesMade++
        }

        /*
         * Will consider an empty string equal to the intial form's null value
         * if a user clicks into a text field, enters a value, and deletes all of it.
         */
        if (form[key] === "" && initialForm[key] === null) {
          falseChanges++
        }
      }

      setIsButtonDisabled(changesMade === falseChanges ? true : false)
    }
  }

  const applyInitCaps = (str) => {
    if (str === "Army Program") str = "Army_Program"
    const words = str.split("_")
    return words.map((word) => word[0].toUpperCase() + word.substring(1)).join("")
  }

  const setFormBasedOnType = () => {
    id ? fetchRecords() : buildForm()
    setLoaded(true)
  }

  const fetchRecords = async () => {
    if (operation === "edit") {
      apiCalls
        .getById(id, applyInitCaps(entity), "")
        .then((result) => {
          setForm(result.data)
          setInitialForm(result.data)
        })
        .catch((error) => {
          console.error(("Error:", error))
          notify("error", `There was a problem loading your ${location.state.title} item. Please try again.`)
        })
    }
  }

  const handleChange = (e) => {
    const { id, name, type, value } = e.target

    // Add a time and offset to each field of the "date" type.
    if (type === "date") setForm((prevFormData) => ({ ...prevFormData, [id || name]: value + " " + SetTimeAndOffset() }))
    else setForm((prevFormData) => ({ ...prevFormData, [id || name]: value }))
  }

  const handleFinalSerialNumberRows = (rows) => {
    setFinalSerialNumberRows(rows)
  }

  const handleFinalDocumentRows = (rows) => {
    setFinalDocumentRows(rows)
  }

  const handleFedLogSelect = (fedlogData) => {
    if (fedlogData) {
      setForm((prevFormData) => ({
        ...prevFormData,
        federalSupplyClassification: fedlogData?.fsc,
        nomenclature: fedlogData?.item_name,
        nationalItemIdentificationNumber: fedlogData?.niin,
        partNumber: fedlogData?.part_number,
        commercialAndGovernmentEntityCode: fedlogData?.cage_code,
        unitOfMeasurement: fedlogData?.um,
        sourceOfSupply: fedlogData?.sos,
        unitOfIssue: fedlogData?.ui,
        unitPrice: fedlogData?.army_unit_price,
        // FEDLOG/COSIS FIELDS
        accountingRequirementsCode: fedlogData?.arc,
        controlledInventoryItemCode: fedlogData?.ciic,
        automaticReturnItemList: fedlogData?.aril,
        criticalityCode: fedlogData?.critl_code,
        demilitarizationCode: fedlogData?.demil_code,
        hazardousMaterielIndicatorCode: fedlogData?.hmic,
        specialControlItemCode: fedlogData?.scic,
        supplyCategoriesOfMaterielCode: fedlogData?.scmc,
        shelfLifeCode: fedlogData?.slc,
        specialRequirementsCode: fedlogData?.src,
        materielCategoryStructureFourAndFive: fedlogData?.matcat_4_5,
        exchangePrice: fedlogData?.exchange_price,
        typeOfStorageArmy: fedlogData?.tos_army,
        hazardousCharacteristicsCode: fedlogData?.hcc,
        controlledInventoryItemCodeAmdf: fedlogData?.ciic_flis,
        methodOfPreservationArmy: fedlogData?.army_mop,
        electrostaticDischargeCode: fedlogData?.esd,
        nationalStockNumber: fedlogData?.fsc + fedlogData?.niin,
      }))
    }

    // Mark the FEDLOG data as having been pulled for the inital entry.
    setFedLogDataPulled(true)

    // Keep track of any new NSN, NIIN, and P/N values.
    setTrackFields({
      nationalStockNumber: fedlogData?.fsc + fedlogData?.niin,
      nationalItemIdentificationNumber: fedlogData?.niin,
      partNumber: fedlogData?.part_number,
    })

    toggleModal(false)
    handleFedLogConfirmClose(false)
  }

  // Enable FEDLOG lookup whenever the NIIN, P/N, and NSN changes (in 'new' mode).
  const handleBlur = (e) => {
    const { id, value } = e.target
    /*
     * If NIIN, P/N, or NSN is changed in edit mode, do NOT pull other fields from FEDLOG.
     * When the NIIN, P/N, or NSN change is approved, make call to FEDLOG API to apply
     * changes to the other fields (only if there is no existing value already in field).
     */
    if (operation !== "edit") {
      /*
       * If FEDLOG data hasn't already been pulled, or if the value has changed for the
       * NSN, NIIN, or P/N fields, then pull FEDLOG data.
       */
      if (!fedLogDataPulled || form[id] !== trackFields[id]) {
        if ((id === "nationalItemIdentificationNumber" && value.match(/\d{9}/)) || id === "partNumber" || id === "nationalStockNumber") {
          handleFedLogConfirmClose(true)
          apiCalls
            .fedLogLookup(id, value)
            .then((response) => {
              if (response.data && response.data.length > 1) {
                setFedlogItems(response.data)
                toggleModal(true)
              } else if (response.data && response.data.length > 0) {
                handleFedLogSelect(response.data[0])
              }
            })
            .catch((err) => {
              notify("error", err.message)
            })
            .finally(() => {
              handleFedLogConfirmClose(false)
            })
        }
      }
    }
  }

  const handleRadioChange = (e) => {
    if (e.target.value === radioValue) {
      setRadioValue("")
      // Reset the quantity if the radio button is unselected.
      form.quantity = ""
    } else setRadioValue(e.target.value)
  }

  const expandSerialNumberEntry = () => {
    return (
      <Grid
        item
        sx={{ minWidth: "100%" }}
      >
        <Accordion sx={{ minWidth: "100%" }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontSize: "small", color: "gray" }}
          >
            Serial #
          </AccordionSummary>
          <AccordionDetails>
            <SerialNumbersInputForm
              radioValue={radioValue}
              handleRadioChange={handleRadioChange}
              form={form}
              handleChange={handleChange}
              finalSerialNumberRows={finalSerialNumberRows}
              handleFinalSerialNumberRows={handleFinalSerialNumberRows}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }

  const expandDocumentEntry = () => {
    return (
      <Grid
        item
        sx={{ minWidth: "100%" }}
      >
        <Accordion
          defaultExpanded={operation === "edit" ? true : false}
          sx={{ minWidth: "100%" }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontSize: "small", color: "gray" }}
          >
            {<UploadFileIcon fontSize="small" />} Upload Document(s)
          </AccordionSummary>
          <AccordionDetails>
            <DocumentsInputForm
              operation={operation}
              type="inventoryRecord"
              programName={getProgramName(selectedGlobalProgramId, context.allProgramsData)}
              recordId={id}
              finalDocumentRows={finalDocumentRows}
              handleFinalDocumentRows={handleFinalDocumentRows}
              handleFileConversion={getBase64}
              isLoading={null}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }

  const getTextField = (field, title, type) => {
    let isTotalCost = field === "totalCost"

    // Disable the quantity field and set to '1' if a serial number radio button is selected.
    let isQuantity = field === "quantity" && radioValue

    // Disable all other fields except for the Asset ID/AESIP fields for Inventory Managers.
    let isAssetIdOrAesip
    field !== "assetIdentification" && field !== "armyEnterpriseSystemIntegrationProgramSerializationIndicator" && context.isInventoryManager
      ? (isAssetIdOrAesip = true)
      : (isAssetIdOrAesip = false)
    return (
      <Grid
        item
        sx={{ minWidth: "100%" }}
      >
        <FormControl sx={{ minWidth: "100%" }}>
          <TextField
            id={field}
            label={title}
            type={type}
            value={
              isTotalCost ? parseFloat((form.totalCost = form.quantity * form.unitPrice)).toFixed(2) : isQuantity ? (form.quantity = 1) : form[field]
            }
            onChange={handleChange}
            onBlur={handleBlur}
            InputLabelProps={{ shrink: true }}
            size="small"
            required={field === "name" || field === "buildingNumber"}
            disabled={isTotalCost || isQuantity || isAssetIdOrAesip}
          />
        </FormControl>
      </Grid>
    )
  }

  const getDateField = (field, title, type) => {
    /*
     * Disable the COSIS date fields if either "99" or "99 - LLRC" are selected as the Packaging Deficiency when adding a record.
     * The dates are automatically generated in the backend if either of the two are selected.
     */
    const isCosisDueDateDisabled = field === "cosisDueDate" && (form["packagingDeficiency"] === "99" || form["packagingDeficiency"] === "99 - LLRC")
    const isCosisCompleteDateDisabled =
      field === "cosisCompleteDate" && (form["packagingDeficiency"] === "99" || form["packagingDeficiency"] === "99 - LLRC")
    return (
      <Grid
        item
        xs={5}
        sx={{ minWidth: "100%" }}
      >
        <FormControl sx={{ minWidth: "100%" }}>
          <InputLabel shrink>{title}</InputLabel>
          <OutlinedInput
            id={field}
            size="small"
            margin="dense"
            notched
            label={title}
            type={type}
            value={!form[field] ? form[field] : getFormattedDate(form[field].replace(" ", "T"))}
            onChange={handleChange}
            onBlur={handleCosisDueDate}
            disabled={(operation === "new" && (isCosisDueDateDisabled || isCosisCompleteDateDisabled)) || context.isInventoryManager}
          />
        </FormControl>
      </Grid>
    )
  }

  const getSelectField = (field, title, type, options) => {
    const isCCDisabled = field === "conditionCode" && operation === "edit" && !context.isTI
    const isParent = title.includes("Parent")
    const isAPorWH = (title.includes("Program") && !isParent) || title.includes("Warehouse")
    const titleType = title.includes("Program") ? "allProgramsData" : "warehouseData"
    const titleToDisplay = title.includes("Program") && !isParent ? "Program" : title
    const isPPDDdisabled = context.finalProgramsList.find((el) => el.id === id)?.subPrograms

    const determineParentProgramDropDown = (id) => {
      let { parentPrograms } = context
      if (!form.parentProgramId) {
        return parentPrograms.filter((prog) => prog.id !== id)
      } else if (form.parentProgramId) {
        return parentPrograms
      }
    }

    return (
      <Grid
        item
        sx={{ minWidth: "100%" }}
      >
        <FormControl
          sx={{ minWidth: "100%" }}
          required={isAPorWH && !isParent}
        >
          <InputLabel
            shrink
            id={`${title}-label`}
          >
            {titleToDisplay}
          </InputLabel>
          <Select
            sx={{ textAlign: "left" }}
            displayEmpty
            name={isParent ? "parentProgramId" : isAPorWH ? `managementRecord${applyInitCaps(title)}Id` : field}
            id={field}
            size="small"
            margin="dense"
            disabled={isPPDDdisabled || isCCDisabled || context.isInventoryManager}
            label={titleToDisplay}
            labelId={`${title}-label`}
            value={form[field] || ""}
            type={type}
            onChange={handleChange}
            input={
              <OutlinedInput
                notched
                label={titleToDisplay}
              />
            }
          >
            {isParent && (
              <MenuItem
                key="no-parent-prog"
                value=""
              >
                No Parent Program
              </MenuItem>
            )}
            {isParent &&
              determineParentProgramDropDown(id).map((el) => {
                return (
                  <MenuItem
                    key={uuidv4()}
                    value={el.id}
                  >
                    {el.name}
                  </MenuItem>
                )
              })}
            {isAPorWH
              ? context[titleType]?.map((el) => {
                  return (
                    <MenuItem
                      key={uuidv4()}
                      value={el.id}
                    >
                      {titleType.includes("Programs") ? el.name : el.buildingNumber}
                    </MenuItem>
                  )
                })
              : options
              ? Object.keys(options)?.map((el) => {
                  let hasNumber = el === "FIFTY_NINE" || el === "SIXTY" || el === "B_SEVEN" || el === "Y_FOUR"
                  return (
                    <MenuItem
                      key={uuidv4()}
                      value={el}
                    >
                      {title === "Type" ? formatEnum(el) : hasNumber ? numberInUOM[el] : el}
                    </MenuItem>
                  )
                })
              : entity.includes("inventory") &&
                context.deficiencyData
                  ?.sort((a, b) => a.number - b.number)
                  .map((el) => {
                    return (
                      <MenuItem
                        key={el.id}
                        value={el.number}
                      >
                        {`${el.number}: ${el.description}`}
                      </MenuItem>
                    )
                  })}
          </Select>
        </FormControl>
      </Grid>
    )
  }

  const buildForm = () => {
    let entityFields = context[entity].formFields
    return entityFields?.map((item) => {
      let { field, title, type, options } = item
      return (
        (type === "date" && getDateField(field, title, type)) ||
        (type === "text" && getTextField(field, title, type)) ||
        (type == "expand" && (operation === "new" ? expandSerialNumberEntry() : getTextField(field, title, "text"))) ||
        (type === "select" && getSelectField(field, title, type, options)) ||
        (type === "upload" && expandDocumentEntry())
      )
    })
  }

  const AddProgramToKeycloak = async (program) => {
    try {
      let groupObject = {
        name: program[0].name,
        attributes: {
          Id: [program[0].id.toUpperCase()],
        },
      }
      await apiCalls.postKeycloakGroup(groupObject)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const AddProgramFolderToMinio = async (program) => {
    try {
      let basicRequest = {
        bucketName: "awims",
        filePath: program[0].name,
      }
      await apiCalls.putFolder(basicRequest)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  // Handles the overlay shown while the FEDLOG data is loaded in.
  const handleFedLogConfirmClose = (response) => {
    setLoadingOverlay(response)
  }

  // Handles logic on open/close of the reasons modal.
  const handleConfirmClose = async (reasoning) => {
    if (!reasoning) {
      setIsReasonsModalOpen(false)
      setIsButtonDisabled(false)
    } else {
      try {
        setIsReasonsModalOpen(false)

        // Handle any non-pending properties.
        let nonPendingProps = pendingProperties.filter((f) => !pattern.test(f))

        await handleNonPendingProperties(nonPendingProps)
          .then(async () => {
            let newForm = {
              ...form,
              reason: reasoning,
            }
            await apiCalls.putPendingRecordChanges(newForm, applyInitCaps(entity), id)
          })
          .then(() => {
            notify("success", `Changes have successfully been marked as pending for approval.`)
            handlePendingChangesCount(pendingCount, applyInitCaps(entity))
            setTimeout(() => {
              navigate(`/${entity}`)
            }, 4000)
          })
      } catch (error) {
        console.error("Error:", error)
        setIsButtonDisabled(false)
        let errorsResp = error.response?.data?.errors
        let variable = await handleFormErrors(errorsResp, entity)
        setFormErrors(variable)
        notify("error", `${errorMessageToDisplay}`)
      }
    }
  }

  // Handles user's action in warning modal when duplicate serial number(s) are found.
  const handleCloseWarning = async (response) => {
    if (response === "bypass") {
      // Continue with adding new records even if there are duplicates.
      setIsDuplicatesFoundModalOpen(false)
      operation === "new" ? await handleNewInventoryRecord(form, entity) : handleEditInventoryRecord(form)
    } else {
      // Close out of modal so user can fix duplicate(s).
      setIsDuplicatesFoundModalOpen(false)
      setIsButtonDisabled(false)
    }
  }

  /*
   * Checks for duplicate serial number(s) when adding/editing.
   * A duplicate is determined by the same NSN, P/N, and S/N.
   */
  const handleSerialNumberDuplicates = async () => {
    // Remove any blank serial numbers.
    let serialNumbers = finalSerialNumberRows.map((f) => {
      if (f.serialNumber !== "") return f.serialNumber
    })
    let intersecting = []

    try {
      let initialCall = await apiCalls.filterForDuplicateNumbers(form["partNumber"], form["nationalStockNumber"])
      if (initialCall.status === 200) {
        let existingSerials = initialCall.data.value.map((f) => {
          return { serialNumber: f.serialNumber, propertyNumber: f.propertyNumber }
        })

        /*
         * NOTE: Handles whether a single or multiple serial numbers were entered and
         * each operation scenario (if editing, there will be no option to select a radio button
         * for the serial number field.)
         */
        if (radioValue === "single" || radioValue === "") {
          // Remove the current record from existingSerials so it doesn't catch itself as a duplicate.
          let newExistingSerials = existingSerials.filter(
            (f) => f.propertyNumber !== form["propertyNumber"] && f.serialNumber !== initialForm["serialNumber"]
          )
          intersecting = [form["serialNumber"]].filter((f) => newExistingSerials.some((el) => el.serialNumber === f))
        } else intersecting = serialNumbers.filter((f) => existingSerials.some((el) => el.serialNumber === f))
        setIntersectingSerials(intersecting)

        if (intersecting.length > 0) setIsDuplicatesFoundModalOpen(true)
        else operation === "new" ? await handleNewInventoryRecord(form, entity) : handleEditInventoryRecord(form)
      }
    } catch (error) {
      console.error("Error:", error)
      setIsDuplicatesFoundModalOpen(false)
      setIsButtonDisabled(false)
      notify("error", `${errorMessageToDisplay}`)
    }
  }

  // Grab the properties that have been changed.
  const getObjectDifferences = (newObj, oldObj) => {
    if (Object.keys(oldObj).length == 0 && Object.keys(newObj).length > 0) return newObj
    let diff = {}
    for (const key in oldObj) {
      if ((newObj[key] || newObj[key] === "") && oldObj[key] !== newObj[key]) {
        diff[key] = newObj[key]
      }
    }
    if (Object.keys(diff).length > 0) return diff
    return oldObj
  }

  // Make any changes to the COSIS dates and CC without having to provide reasoning.
  const handleNonPendingProperties = async (nonPendingProps) => {
    let patchChanges = nonPendingProps.map((property) => {
      return {
        path: property,
        op: "replace",
        value: form[property],
      }
    })
    return await apiCalls.patchRecordMultiple(params.id, "InventoryRecords", patchChanges)
  }

  // Sets the new COSIS Requirements Date if the COSIS Complete Date is modified.
  const handleCosisDueDate = (e) => {
    const { id } = e.target
    if (id === "cosisCompleteDate" && form["cosisCompleteDate"] != null) {
      apiCalls
        .getNewCosisDueDate(form["managementRecordWarehouseId"], getFormattedDate(form["cosisCompleteDate"].replace(" ", "T")))
        .then((response) => {
          // NOTE: If no warehouse type was set and the response is empty, just keep the previous value.
          let updateForm = {
            ...form,
            cosisDueDate: !response.data ? form["cosisDueDate"] : response.data,
          }
          setForm(updateForm)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const handleFormErrors = async (errorArray, entity) => {
    let isInventory = entity.includes("inventory")

    let errors = errorArray ? errorArray : []
    const errorMessages = []

    if (errorArray) {
      errors.forEach((error) => errorMessages.push(error))
    }

    if ((isInventory && !form.managementRecordArmyProgramId) || (isInventory && !form.managementRecordWarehouseId)) {
      errorMessages.push("Program and Warehouse selections are required.")
    }

    // NOTE: Only throw this error when adding a new inventory item, or else it'll be thrown for all existing items that don't have documents.
    if (isInventory && operation === "new" && finalDocumentRows.length === 0) {
      errorMessages.push("At least one uploaded document is required.")
    }

    // NOTE: Throw this error if the user selects a radio button for the serial numbers but puts in no serial values.
    if (isInventory && operation === "new" && radioValue !== "" && form.serialNumber === "" && finalSerialNumberRows.length === 0)
      errorMessages.push(
        "If this is meant to be a bulk item, please unselect the option made under the Serial Number drop-down. If not, please put in a value(s) for that field."
      )

    if (isInventory && !form.quantity) {
      errorMessages.push("Quantity is required.")
    }

    return errorMessages
  }

  // Add initial files into the program's 'Inventory Documents' folder.
  const handleFileSubmission = async (recordId, programName) => {
    let documentIds = []
    await Promise.all(
      finalDocumentRows.map(async (el) => {
        await new Promise(getBase64(el.fileObject)).then(async (result) => {
          let basicRequest = {
            bucketName: "awims",
            objectName: el.fileName,
            filePath: `${programName}/Inventory Documents`,
            file: result,
          }

          try {
            let response = await apiCalls.putFile(basicRequest)
            documentIds.push(response.data.result.value.id)
          } catch (error) {
            console.error("Error:", error)
          }
        })
      })
    )
      .then(async () => {
        // Once document(s) are added, create relationship between record and document(s).
        await Promise.all(
          documentIds.map(async (el) => {
            let request = {
              inventoryRecordId: recordId,
              documentId: el,
            }

            await apiCalls.postRecord(request, "AttachmentRecords")
          })
        )
      })
      .catch((error) => {
        console.error("Error in handleFileSubmission():", error)
      })
  }

  const handleValidationCheck = async (item) => {
    /*
     * NOTE: Because the serialized records get passed to Hangfire, the object isn't validated and doesn't end up getting added
     * since any errors get thrown while the task is running in the background. We'll need to first check if the object is
     * correct and if it is, make the Hangfire call. We can pass the first object in the "items" array to validate. We can do this because
     * whether it's a single serialized item or multiple, they'll have the same values for all fields EXCEPT for the serial number. This field
     * doesn't have any special formatting anyway, so we want to ensure the formatting is correct for all the other fields.
     */
    let validationResponse = await apiCalls.validateRecord("InventoryRecords", item)
    return validationResponse
  }

  const handleProgramsWHOps = async (form, entity) => {
    try {
      setFormErrors([])

      if (operation === "new") {
        let postResp = await apiCalls.postRecord(form, applyInitCaps(entity))

        if (postResp.status === 201 && entity.includes("programs")) {
          await handleUpdateUserPrograms(postResp.status, form)
          let program = await apiCalls.getArmyProgramByName(form.name)
          await AddProgramToKeycloak(program.data.value)
          await AddProgramFolderToMinio(program.data.value)
        }
        notify("success", `${successMessageToDisplay}`)
        setTimeout(() => {
          navigate(`/${entity}`)
        }, 4000)
      } else {
        let putResp = await apiCalls.putRecord(form, applyInitCaps(entity), id)
        if (putResp.status === 204 && entity.includes("programs")) {
          await handleUpdateUserPrograms(putResp.status, form)
        }
        notify("success", `${successMessageToDisplay}`)
        setTimeout(() => {
          navigate(`/${entity}`)
        }, 4000)
      }
    } catch (error) {
      console.error("Error:", error)
      let errorsResponse = error.response?.data?.errors
      let errorMessages = await handleFormErrors(errorsResponse, entity)
      setIsButtonDisabled(false)
      setFormErrors(errorMessages)
      notify("error", `${errorMessageToDisplay}`)
    }
  }

  const handleNewInventoryRecord = async (form, entity) => {
    let programName = getProgramName(form["managementRecordArmyProgramId"], context.allProgramsData)
    try {
      // Used to show screen overlay as record is added.
      setLoadingOverlay(true)
      setFormErrors([])
      // Since the uploads aren't validated as part of the POST object in the BE, check the document row count and throw an error if equal to 0.
      if (finalDocumentRows.length === 0) throw new Error("At least one uploaded document is required.")

      let items = []
      if (radioValue) {
        if (form.serialNumber === "" && finalSerialNumberRows.length === 0)
          throw new Error(
            "If this is meant to be a bulk item, please unselect the option made under the Serial Number drop-down. If not, please put in a value(s) for that field."
          )

        // If there are multiple entries, remove any empty ones the user may have accidentally entered.
        let filteredRows = finalSerialNumberRows.filter((item) => item.serialNumber.length > 0 && item.serialNumber.replace(/\s/g, "").length !== 0)
        radioValue === "single"
          ? items.push(form)
          : (items = filteredRows.map((f) => {
              return {
                ...form,
                serialNumber: f.serialNumber,
              }
            }))
      }

        let documentIds = []

        // Add file(s) to the program's folder under "Inventory Documents" in MinIO.
        await Promise.all(
          finalDocumentRows.map(async (el) => {
            await new Promise(getBase64(el.fileObject)).then(async (result) => {
              let basicRequest = {
                bucketName: "awims",
                objectName: el.fileName,
                filePath: `${programName}/Inventory Documents`,
                file: result,
              }

              try {
                let response = await apiCalls.putFile(basicRequest)
                documentIds.push(response.data.result.value.id)
              } catch (error) {
                console.error("Error:", error)
              }
            })
          })
        ).then(async () => {
          let hangfireObject = {
          inventoryRecords: radioValue ? items : [{ ...form, serialNumber: "" }],
            documentIds: documentIds,
            userEmail: context.userEmail,
          }

        let validationResponse = await handleValidationCheck(radioValue ? items[0] : [{ ...form, serialNumber: "" }][0])
          if (validationResponse.status === 200) await apiCalls.postRecordWithHangfire(hangfireObject, "HangfireJobs")
        })

      setLoadingOverlay(false)
      notify("success", "Inventory record(s) are being added. An email will be sent once the task is completed.")
      setTimeout(() => {
        navigate(`/${entity}`)
      }, 4000)
    } catch (error) {
      console.error("Error:", error, typeof error)
      setLoadingOverlay(false)
      setIsButtonDisabled(false)
      let errorsResp = error.response?.data?.errors
      let variable = await handleFormErrors(errorsResp, entity)
      setFormErrors(variable)
      notify("error", `${errorMessageToDisplay}`)
    }
  }

  const handleEditInventoryRecord = async (form) => {
    // Distinguish b/w pending and non-pending property changes.
    let changedProperties = getObjectDifferences(form, initialForm)
    let filterPendingProperties = Object.keys(changedProperties).filter((f) => pattern.test(f))
    setPendingCount(filterPendingProperties.length)
    try {
      if (filterPendingProperties.length === 0 && Object.keys(changedProperties).length > 0) {
        await handleNonPendingProperties(Object.keys(changedProperties))
        notify("success", `${successMessageToDisplay}`)
        setTimeout(() => {
          navigate(`/${entity}`)
        }, 4000)
      } else {
        setPendingProperties(Object.keys(changedProperties))
        setIsReasonsModalOpen(true)
      }
    } catch (error) {
      console.error("Error:", error)
      notify("error", `${errorMessageToDisplay}`)
    }
  }

  const handleSubmit = async (form, entity) => {
    setIsButtonDisabled(true)

    try {
      if (entity.includes("inventory")) {
        // If a bulk item is being added/edited, there's no need to check for duplicates serials.
        if (radioValue === "" && !form["serialNumber"])
          operation === "new" ? await handleNewInventoryRecord(form, entity) : await handleEditInventoryRecord(form)
        else handleSerialNumberDuplicates()
      } else {
        await handleProgramsWHOps(form, entity)
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  // ** RENDER ** //
  if (loaded) {
    return (
      <>
        {loadingOverlay && <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />}
        {isReasonsModalOpen && (
          <InventoryChangeReasonModal
            shouldOpen={true}
            handleConfirmClose={handleConfirmClose}
            title="State reasoning for change(s)."
            type="add"
            text=""
          />
        )}
        {isDuplicatesFoundModalOpen && (
          <DuplicatesFoundModal
            shouldOpen={true}
            handleConfirmClose={handleCloseWarning}
            title="WARNING: Duplicate(s) Found"
            text={{ intersectingSerials: intersectingSerials, partNumber: form["partNumber"], nationalStockNumber: form["nationalStockNumber"] }}
          />
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
          {isModalOpen && (
            <FedLogSelectModal
              shouldOpen={true}
              handleFedLogSelect={handleFedLogSelect}
              fedlogItems={fedlogItems}
            />
          )}
          <Container className="form-bg">
            <div className="back-btn-wrapper">
              <IconButton
                onClick={() => navigate(-1)}
                size="large"
              >
                <ArrowBackIcon fontSize="inherit" />
              </IconButton>
            </div>
            <Box className="form-grid">
              <div className="create-header">
                <Typography
                  variant="h1"
                  sx={{
                    marginBottom: "9px",
                  }}
                >
                  {operation === "new" ? `New ${location.state.title} Entry` : `Edit ${location.state.title} Entry`}
                </Typography>
              </div>

              <Grid
                container
                spacing={2}
                sx={{ width: "100%" }}
              >
                {buildForm()}
              </Grid>

              {formErrors.length > 0 && (
                <div className="error-container">
                  <ul style={{ listStyle: "none", paddingLeft: 0, margin: 0 }}>
                    {formErrors.map((err) => {
                      return <li className="error-msg">{err}</li>
                    })}
                  </ul>
                </div>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Button
                  onClick={() => handleSubmit(form, entity)}
                  variant="contained"
                  disabled={isButtonDisabled}
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    width: "200px",
                    backgroundColor: "#105075",
                    border: "2px solid #646464",
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Container>
        </div>
      </>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default CustomForm
